import * as React from "react";
import styeld from "styled-components";
import background1 from "../../images/space/space4-1.png";
import background2 from "../../images/space/space4-2.png";
import background3 from "../../images/space/space4-3.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";
const Containar = styeld.div`

.space4-space2 {
  display: flex;
  align-items: flex-end;
  img{
    width: 47%;
    display: block;
  }
  .space4-text{
    margin-left: 3vw;
    margin-bottom: 3vw;
  .space4-title{
    font-size:2.5vw;
    font-family: 'Pretendard-Black';
    margin-bottom: 2vw;
    span{
      color:#86C107;
    }
  }
  .space4-subtitle{
    font-family: 'Pretendard-Medium';
    font-size: 1.25vw;
    line-height: 1.3;
    color: #555555;
  }
}
  .space4-lastImg {
    width: 34vw;
  }
}
.space4-firstImg{
  margin-top: -1px;
  position: relative;
  display: block;
  width: 47%;
  height: fit-content;
}

.space4-grayWall{
  position: absolute;
  right: 0;
  width: 19vw;
  height: 70vw;
  background-color: #F6F6F6;
  
}

.horizon-line{
  display: block;
  border-top:1px solid #DEDEDE;
  position: relative;
}

@media screen and (max-width: 1020px) {
  .space4-space2{

    .space4-text {
      margin-left: 2vw;
      .space4-title {
        font-size: 3.8vw;
      }
      .space4-subtitle{
        font-size: 1.6vw;
      }
    }
  }
}
@media screen and (max-width: 460px) {
  margin-top: 40vw;
  .space4-space2 {
    img{
      width:55%;
    }
    .space4-lastImg {
      width:45vw;
    }
  .space4-text {
    position: absolute;
    left: 5%;
    margin: -76vw 0 5vw 0vw;
    .space4-title{
      font-size:6vw;
      margin-bottom: 5vw;
    }
    .space4-subtitle{
      font-size:4vw;
    }
  }
}


    .space4-firstImg {
    width:55%;
    height: intrinsic;
    }
   


  .space4-grayWall{
    margin-top: -100vw;
    width: 25vw;
    height: 100vw;
    z-index: -2;
  }
  .hide-460 {
    display:none;
  }

}
`;

// markup
const Space4 = () => {
  const animation = {
    0: useScrollFadeIn("down", 1, 0),
    1: useScrollFadeIn("down", 1, 0.2),
  };

  const moveAnimate = {
    0: useScrollMove("left", 1, 0.2),
    1: useScrollMove("left", 1, 0.4),
    2: useScrollMove("down", 1, 0.6),
  };
  return (
    <Containar>
      <div>
        <hr className="horizon-line" />
        <div className="space4-grayWall" />
        <img
          alt="img164"

          {...moveAnimate[0]}
          className="space4-firstImg"
          src={background1}
        />
        <div className="space4-space2">
          <img
          alt="img165"
          
          {...moveAnimate[1]} src={background2} />
          <div className="space4-imgAndText">
            <div className="space4-text">
              <p {...animation[0]} className="space4-title">
                공간샘 스터디 오피스
              </p>
              <p {...animation[1]} className="space4-subtitle">
                학습과 업무 모두 가능합니다.
                <br />
                평생 교육시대의 리더로 거듭나세요.
              </p>
            </div>
            <img
          alt="img165"

              {...moveAnimate[2]}
              className="space4-lastImg"
              src={background3}
            />
          </div>
        </div>
      </div>
    </Containar>
  );
};

export default Space4;

import * as React from "react";
import styeld from "styled-components";
import backgroundMedia from "../../images/space/space4-4.png";
import srcollMedia from "../../images/scroll.svg";

const Containar = styeld.div`
.img-filter{
    width: 100%;
    height:100%;
    position: relative;
    top:0;
    left:0;
    bottom:0;
    right:0;
    filter: brightness(0.7);
   img {
    width: 100%;
    display:block;
    position: relative;
  }
}
.space5-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 7.5vw;
  position: absolute;
  z-index: 1;
  text-align: center;
  .space5-title {
    font-family: 'Pretendard-ExtraBold';
    color:#ffffff;
    font-size: 2.5vw;
    line-height: 1.2;
    
  }
  .space5-subtitle{
    margin-top:2vw;
    font-family: 'Pretendard-Medium';
    font-size: 1.25vw;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.8);
  }
}

.show-460 {
  display:none;
}
@media screen and (max-width: 1020px) {
 

    .space5-text {
     
      .space5-title {
        font-size: 4vw;
      }
      .space5-subtitle{
        font-size: 1.8vw;
      }
    }
 
}
@media screen and (max-width: 460px) {

 
  .space5-text {
     margin-top: 13.5vw;
    .space5-title{
      font-size:6vw;
      margin-bottom: 1vw;
    }
    .space5-subtitle{
      font-size:4vw;
    }
  }

  .img-filter {
    height:55vw;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      margin-left: -85vw;
    }
  }
   
  .show-460 {
    display:block;
  }

}

`;

// markup
const space5 = () => {
  return (
    <Containar>
      <div>
        <div className="space5-text">
          <p className="space5-title">
            미래를 준비하는
            <br />
            공간을 디자인합니다.
          </p>
          <p className="space5-subtitle">
            '함께 성장하는 사회'를 위한
            <br className="show-460" />
            공간과 서비스를 디자인합니다.
          </p>
        </div>
        <div className="img-filter">
          <img 
          alt="img166"
          
          src={backgroundMedia} />
        </div>
      </div>
    </Containar>
  );
};

export default space5;

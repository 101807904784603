import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import Space1 from "../components/spacebusiness/space1";
import Space2 from "../components/spacebusiness/space2";
import Space3 from "../components/spacebusiness/space3";
import Space4 from "../components/spacebusiness/space4";
import Space5 from "../components/spacebusiness/space5";
import { Helmet } from "react-helmet";

const Containar = styeld.div`
overflow-x:hidden;
`;
const pageName = "공간 비즈니스";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;
// markup
const Spacebusiness = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/spacebusiness" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta
          property="twitter:url"
          content="https://samlab.co.kr/spacebusiness"
        />
      </Helmet>
      <Containar>
        <Space1 />
        <Space2 />
        <Space3 />
        <Space4 />
        <Space5 />
      </Containar>
    </Layout>
  );
};

export default Spacebusiness;

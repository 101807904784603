import * as React from "react";
import styeld from "styled-components";
import background from "../../images/space/space1.png";
import useScrollFadeIn from "../useScrollFadein";

const Containar = styeld.div`

.img-filter{
    width: 100vw;
    height:100vh;
    position: relative;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 2;
   img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
  .colorFilter {
    background: rgba(3,62,63,.4);
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100vh;
  }
}
.space1-text{
  display: flex;
  height: 100vh;
  margin: -10vw 19% 0;
  position: absolute;
  z-index: 5;
  flex-direction: column;
    justify-content: center;
  .space1-title {
    font-family: 'Pretendard-ExtraBold';
    color:#ffffff;
    font-size: 4vw;
    margin: 15vw 0 0.7vw;
    line-height: 1.2;
  }
  .space1-subtitle{
    font-size: 1.3vw;
    color:#ffffff;
    line-height: 1.5;
    font-family: 'Pretendard-Medium';
  }
}
object{
  width: 2vw;
  display: block;
  margin: 11vw auto 0;
} 
@media screen and (max-width: 1020px) {
  .space1-text {
    margin-left: 7%;
    .space1-title {
      font-size: 7vw;
    }
    .space1-subtitle{
      font-size: 3vw;
    }
  }
}
@media screen and (max-width: 460px){
  .space1-text {
    height: 100vh;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    .space1-title {
      font-size: 10.5vw;
      margin: 40vh 0 0.7vw;
    }
    .space1-subtitle{
      margin: 3vh 0 2vw;
      font-size:4.5vw;
    }
  }

  .img-filter{
    height: 100vh;
    overflow-x:hidden;
    img {
      height: 100%;
      width: auto;
      margin-left:-180vw;
    }
    .colorFilter {
      height: 100%;
    }
  }

  .hide-460 {
    display:none;
  }
}
`;

// markup
const Space1 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.4),
  };

  return (
    <Containar>
      <div>
        <div className="space1-text">
          <p {...animation[0]} className="space1-title">
            학습공간은
            <br />
            다양해야 합니다.
          </p>
          <p {...animation[1]} className="space1-subtitle">
            다양한 학습자를 고려하여 학습과 휴식이
            <br />
            공존하는 공간샘
          </p>
        </div>
        <div className="img-filter">
          <div className="colorFilter" />
          <img alt="img170" src={background}></img>
        </div>
      </div>
    </Containar>
  );
};

export default Space1;

import * as React from "react";
import { Tween } from "react-gsap";
import styeld from "styled-components";
import background from "../../images/space/space2.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";
const Containar = styeld.div`
.border-left {
  margin-top: -9vw;
  width:19%;
  height: 124.5vw;
  border-right: 1px solid #DEDEDE;
  position: absolute;
}
.space2-text {
  text-align: center;
  margin: 7vw 0 5vw;
  .space2-title{
    font-size:2.5vw;
    font-family: 'Pretendard-Black';
    margin-bottom: 2vw;
    span{
      color:#86C107;
    }
  }
  .space2-subtitle{
    font-family: 'Pretendard-Medium';
    font-size: 1.25vw;
    line-height: 1.3;
    color: #555555;
  }
}
.space2-img {
  img {
    float: right;
    margin-right: -16vw;
    margin-bottom: -12vw;
    width: 80%;
    position: relative;
  }
}

.big-text {
  margin-left: 35vw;
  font-family: 'Pretendard-Black';
  font-size: 20.8vw;
  white-space: nowrap;
  clear: both;
  color: rgba(0,0,0,0.07);
}

.hide-460 {
  display:block;
}

.show-460 {
  display:none;
}
@media screen and (max-width: 1020px) {
  .space2-text {
    margin-left: 7%;
    .space2-title {
      font-size: 5vw;
    }
    .space2-subtitle{
      font-size: 1.8vw;
    }
  }
}
@media screen and (max-width: 460px) {
  .space2-text {
    margin: 20vw 0 25vw;
    .space2-title{
      font-size:6vw;
      margin-bottom: 5vw;
    }
    .space2-subtitle{
      font-size:4vw;
    }
  }

  .space2-img {
    img {
      margin-left:-100vw;
      width: auto;
      float:left;
      margin-bottom: -13vw;
    }
  }

  .big-text {    
    margin-left: 49vw;
    font-size: 31vw;
  }
  .hide-460 {
    display:none;
  }

  .show-460 {
    display:block;
  }
}
`;

// markup
const Space2 = () => {
  const animation = {
    0: useScrollFadeIn("down", 1, 0),
    1: useScrollFadeIn("down", 1, 0.4),
  };

  const moveAnimate = {
    0: useScrollMove("right", 1, 0.6),
  };

  let innerh;
  let innerw;

  if (typeof window !== `undefined`) {
    innerh = window.innerHeight;
    innerw = window.innerWidth;
  }

  const vh = (coef) => innerh * (coef / 100);
  const vw = (coef) => innerw * (coef / 100);

  return (
    <Containar>
      <div>
        <div className="border-left hide-460" />
        <div className="space2-text">
          <p {...animation[0]} className="space2-title">
            <span>ECO</span>-FRIENDLY
          </p>
          <p {...animation[1]} className="space2-subtitle">
            특허받은 특별한 방음기술과 프리미엄 친환경주의
            <br className="show-460" />
            인테리어로
            <br className="hide-460" />
            사용자의 건강까지 생각합니다.
          </p>
        </div>
        <div className="space2-img">
          <img alt="img171" {...moveAnimate[0]} src={background}></img>
        </div>
        <div className="hide-460">
          <Tween
            to={{
              x: "-" + vw(180),
              scrollTrigger: {
                start: vh(120) + " center",
                end: vh(190) + " center",
                scrub: 0.5,
                // markers: true,
              },
            }}
          >
            <p className="big-text">SPACE BUSINESS.</p>
          </Tween>
        </div>
        <div className="show-460">
          <Tween
            to={{
              x: "-" + vw(250),
              scrollTrigger: {
                start: vh(120) + " center",
                end: vh(190) + " center",
                scrub: 0.5,
                // markers: true,
              },
            }}
          >
            <p className="big-text">SPACE BUSINESS.</p>
          </Tween>
        </div>
      </div>
    </Containar>
  );
};

export default Space2;

import * as React from "react";
import { Tween } from "react-gsap";
import styeld from "styled-components";
import background1 from "../../images/space/space3-1.png";
import background2 from "../../images/space/space3-2.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";
const Containar = styeld.div`
.border-left {
  margin-top: -9vw;
  width:19%;
  background-color:#F6F6F6;
  position: absolute;
}
.space3-text {
  
  margin: 7vw 0 5vw 19vw ;
  .space3-title{
    font-size:2.5vw;
    font-family: 'Pretendard-Black';
    margin-bottom: 2vw;
    span{
      color:#86C107;
    }
  }
  .space3-subtitle{
    font-family: 'Pretendard-Medium';
    font-size: 1.25vw;
    line-height: 1.3;
    color: #555555;
  }
}
.space3-img {
  display: flex;
  .space3-grayWall{
    position: absolute;
    width: 19vw;
    height: 58vw;
    background-color: #F6F6F6;
    
  }
  .space3-firstImg{
    margin-top: -1px;
    margin-left: 19vw;
    width: 35.7%;
    height: fit-content;
  }
  .space3-lastImg{
    margin-top: -1px;
    width: 66.8%;
    height: fit-content;
  }
}

.horizon-line{
  display: block;
  border-top:1px solid #DEDEDE;
}

.big-text {
  margin: 6vw 0vw 6vw 40vw;
  font-family: 'Pretendard-Black';
  font-size: 20.8vw;
  white-space: nowrap;
  clear: both;
  color: rgba(0,0,0,0.07);
}

.show-460{
  display:none;
}
@media screen and (max-width: 1020px) {
  .space3-text {
    .space3-title {
      font-size: 5vw;
    }
    .space3-subtitle{
      font-size: 1.8vw;
    }
  }
}
@media screen and (max-width: 460px) {
  .space3-text {
    margin:35vw 0 5vw 19vw;
    .space3-title{
      font-size:6vw;
      margin-bottom: 5vw;
    }
    .space3-subtitle{
      font-size:4vw;
    }
  }

  .space3-img {
    
    .space3-firstImg {
      margin-left:0;
      height: intrinsic;
    }
    .space3-lastImg {
      height: intrinsic;
    }
    .space3-grayWall{
      margin-top: -85vw;
      width: 25vw;
      height: 100vw;
      z-index: -2;
    }
  }
  .hide-460 {
    display:none;
  }

  .big-text {  
    margin: 19vw 0vw 6vw 40vw; 
    font-size: 31vw;
  }
  .show-460{
    display:block;
  }
}
`;

// markup
const Space3 = () => {
  const animation = {
    0: useScrollFadeIn("down", 1, 0),
    1: useScrollFadeIn("down", 1, 0.2),
  };

  const moveAnimate = {
    0: useScrollMove("left", 1, 0),
    1: useScrollMove("left", 1, 1),
  };

  let innerh;
  let innerw;

  if (typeof window !== `undefined`) {
    innerh = window.innerHeight;
    innerw = window.innerWidth;
  }

  const vh = (coef) => innerh * (coef / 100);
  const vw = (coef) => innerw * (coef / 100);

  return (
    <Containar>
      <div>
        <div className="border-left hide-460" />
        <div className="space3-text">
          <p {...animation[0]} className="space3-title">
            공간샘 스터디 카페
          </p>
          <p {...animation[1]} className="space3-subtitle">
            5가지의 특별한 공간
            <br />
            나의 성장을 돕는 서비스를 제공합니다
          </p>
        </div>
        <hr className="horizon-line hide-460" />
        <div className="space3-img">
          <div className="space3-grayWall" />
          <img
          alt="img162"
            {...moveAnimate[0]}
            className="space3-firstImg"
            src={background1}
          />
          <img
          alt="img163"

            {...moveAnimate[1]}
            className="space3-lastImg"
            src={background2}
          />
        </div>
        <div className="hide-460">
          <Tween
            to={{
              x: "-" + vw(300),
              scrollTrigger: {
                start: vh(250) + " center",
                end: vh(320) + " center",
                scrub: 0.5,
              },
            }}
          >
            <p className="big-text">FOR YOUR MEANINGFUL LIFE.</p>
          </Tween>
        </div>
        <div className="show-460">
          <Tween
            to={{
              x: "-" + vw(400),
              scrollTrigger: {
                start: vh(220) + " center",
                end: vh(320) + " center",
                scrub: 0.5,
              },
            }}
          >
            <p className="big-text">FOR YOUR MEANINGFUL LIFE.</p>
          </Tween>
        </div>
      </div>
    </Containar>
  );
};

export default Space3;
